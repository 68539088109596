import Vue from "vue";
import VueRouter from "vue-router";
// import { Message } from "element-ui";
// import store from "../store";

// 引入组件
// import Login from "@/views/Login";
import Home from "@/views/Home";
import Homepage from "@/views/Homepage";
import CareerPlanning from "@/views/CareerPlanning";
import MbaProject from "@/views/MbaProject";
import StudyingAbroad from "@/views/StudyingAbroad";
import SmartCampus from "@/views/SmartCampus";
import SmartSports from "@/views/SmartSports";
import WisdomTeaching from "@/views/WisdomTeaching";
// import QueryCondition from "@/views/queryCondition";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/Home/Homepage" },
    {
      path: "/Home",
      component: Home,
      meta: { isHiddenFooter: true },
      children: [
        {
          path: "Homepage",
          component: Homepage,
          meta: { title: "首页" },
        },
        {
          path: "CareerPlanning",
          component: CareerPlanning,
          meta: { title: "生涯规划" },
        },
        {
          path: "MbaProject",
          component: MbaProject,
          meta: { title: "考研规划" },
        }, 
        {
          path: "StudyingAbroad",
          component: StudyingAbroad,
          meta: { title: "留学规划" },
        },
        {
          path: "SmartCampus",
          component: SmartCampus,
          meta: { title: "智慧校园" },
        },{
          path: "SmartSports",
          component: SmartSports,
          meta: { title: "智慧体育" },
        },{
          path: "WisdomTeaching",
          component: WisdomTeaching,
          meta: { title: "智慧教学" },
        },
      ],
    },
    {
      path: "/404",
      component: () => import("@/views/404.vue"),
      hidden: true,
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
  // 每次路由跳转触发函数
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

// 设置路由导航守卫
/*
  路由导航守卫：当路由跳转的时候触发的狗子函数
    1. 全局盗号守卫(对所有路由生效)
      beforeEach
      beforeResolve
      afterEach
    2. 路由导航守卫(只对当前路由生效)
    3. 组件导航守卫(只对当前组件生效)
*/

// 定义白名单
// const whiteList = ["/login", "/404"];

// 路由守卫
// router.beforeEach((to, from, next) => {
//   const token = store.state.user.token;
//   if (token) {
//     // 登录了
//     if (to.path === "/login") {
//       next("/");
//     } else {
//       // 登录之后访问其他页面都正常
//       next();
//     }
//   } else {
//     // 没有登录不能访问whiteList，要登录在进行访问
//     if (whiteList.includes(to.path)) {
//       Message.error("请登录在进行访问");
//       next();
//     } else {
//       // sessionStorage.clear();
//       next("/login");
//     }
//   }
// });

export default router;
