<template>
    <div class="box">
        <div class="content">
            <div class="title">智慧安全</div>
            <div class="explain">
                <p>
                    智慧安全系统是智能硬件配套和数字化管理系统为一体的以视频监控为主要形式的产品。
                </p>
                <p>智能管理系统可以全流程追踪记录校园生活，高效实时云同步，一屏具览，保障管理者多方协作多个硬件设施的便利性和各人员的分工协作。</p>
            </div>
            <div class="headline">
                <div class="s"></div>
                <div class="scene">使用场景1</div>
                <div>师生入校</div>
            </div>
            <div class="contentBox">
                <div class="img">
                    <img src="./images/编组 2@2x.png" alt="">
                </div>
            </div>
            <div class="headline">
                <div class="s"></div>
                <div class="scene">使用场景2</div>
                <div>校园生活</div>
            </div>
            <div class="schoolLifeBox">
                <div class="top">
                    <div class="left">
                        <div class="tit">操场&教学楼</div>
                        <div class="tags">
                            <div class="tag">剧烈运动监测</div>
                            <div class="tag">倒地监测</div>
                            <div class="tag">人口密度监测</div>
                            <div class="tag">奔跑监测</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="tit">校园</div>
                        <div class="tags">
                            <div class="tag">智能井盖检测</div>
                            <div class="tag">智能烟感、温感</div>
                            <div class="tag">智能水压检测</div>
                            <div class="tag">智能消防栓检测</div>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <div class="left">
                        这部分功能主要用于智能监测校园内会威胁到自身安全的危险行为，其中包含移动速度超过阈值设定，人员密集度过高，剧烈运动和意外摔倒等情况。通过警报或者人员通知，达到及时救助或处理的目的，降低威胁师生安全的风险。
                    </div>
                    <div class="center">
                        <img src="./images/e4ab6cb775152516d35178e537236c9fa6757de5293a2-1sn3Hq@2x.png" alt="">
                    </div>
                    <div class="right">
                        智能硬件设施配套主要提供了对潜在危险的识别，监测和警示功能。将传统，笼统的硬件维护模式发展为数字化和可视化的全新智能模式，方便管理人员及时发现安全隐患，轻减维护繁琐步骤，避免校园安全风险。</div>
                </div>
            </div>
            <div class="title">智慧管理</div>
            <div class="explain">
                <div style="margin-bottom: 40px;">
                    智慧管理模块构建了一体化教务管理、教学综合管理、学生成长档案管理、智能办公管理四大管理子平台，内涵二十个应用系统，全方位覆盖学校教学、办公、管理、生活等各个业务层面，提升校园综合管理能力。
                </div>
                <div style="padding-bottom: 40px;">管理数据的统一采集与存储、业务流程的统一运行与监控可以有效提高管理效率和质量。</div>
            </div>
        </div>
        <div class="content iscontent">
            <div>教务管理</div>
            <div>教学综合管理</div>
            <div>学生家长档案管理</div>
            <div>智能办公管理</div>
        </div>
        <div class="list content">
            <div class="left">
                <div class="top">
                    <img src="./images/编组 3@2x.png" alt="">
                </div>
                <div class="tit">云上校园</div>
                <div class="btn">基于整合5G赋能、人工智能技术优势，建设5G+远程直播场景，提供多点、多地、多视觊的超高清直播、点播服务，应用于探索校园开放日，校园于毕业等场景，另家长学生全面了解心仪学校。
                </div>
            </div>
            <div class="right">
                <div class="top"><img src="./images/编组 4@2x.png" alt=""></div>
                <div class="tit">智慧资产管理</div>
                <div class="btn">
                    可视化多维架构管理，呈现不同维度脉络，满足多元管理需求。校园信息化设备管理，人员物资盘点，课程设置和活动组织，信息存储和回溯等功能让管理者动态掌握学校全貌，优化管理决策，实现校园资产智能化管理。
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'EveryTreeAndBushIndex',
    data() {
        return {
        }
    },

    mounted() {

    },

    methods: {

    },
};
</script>
  
<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    height: 100%;
}

.content {
    padding: 0 10%;
    background: #FFFFFF;

    .title {
        text-align: center;
        padding-top: 140px;
        font-size: 56px;
    }

    .explain {
        margin-top: 80px;
        font-size: 24px;

        p {
            margin-bottom: 40px;
        }
    }

    .headline {
        display: flex;
        align-items: center;
        font-size: 36px;

        .s {
            width: 6px;
            height: 40px;
            background: #3964E6;
        }

        .scene {
            margin: 0 67px 0 20px;
        }
    }

    .contentBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
            margin-top: 177px;
            margin-bottom: 104px;
            width: 825px;
        }
    }

    .schoolLifeBox {
        margin-top: 113px;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left,
            .right {
                width: 45%;

                .tit {
                    font-size: 36px;
                    margin-bottom: 5px;
                }


                .tags {
                    display: flex;
                    flex-wrap: wrap;
                }

                .tag {
                    margin-right: 26px;
                    margin-top: 25px;
                    width: 44%;
                    height: 60px;
                    background: #F4F7FF;
                    color: #3964E6;
                    text-align: center;
                    line-height: 60px;
                    font-size: 24px;
                    font-weight: bold;
                }
            }
        }

        .btn {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;

            .left {
                width: 345px;
            }

            .center {
                width: 292px;
                height: 265px;
            }

            .right {
                width: 345px;
            }
        }
    }

    .iscontent {
        height: 130px !important;
        background: linear-gradient(108deg, #3964E6 0%, #A4A8EE 100%) !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 34px;
        color: #FFFFFF;
        text-align: center;
        line-height: 130px;
    }

    .list {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        .left {
            margin-right: 31px;
            width: 586px;
        }

        .right {
            width: 586px;
        }

        .top {
            width: 586px;
            height: 290px;
        }

        .tit {
            margin: 35px 0;
            font-size: 36px;
            font-weight: bold;
        }

        .btn {
            font-size: 24px;
            margin-bottom: 107px;
        }
    }
}
</style>