<template>
  <div class="box">
    <div class="academicAptitudeTest">
      <div class="title">成长不止聚焦学业能力</div>
      <div class="list">
        <div class="top">
          <div class="item" v-for="(it, idx) in list1" :key="idx">
            <div class="headline">
              <div class="text">{{ it.title }}</div>
            </div>
            <div class="content">
              <div class="left">
                <div class="it" v-for="(its, idxs) in it.item" :key="idxs">
                  {{ its.text }}
                </div>
              </div>
              <div class="right">
                <div class="it">
                  {{ it.con }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <div class="item" v-for="(it, idex) in list2" :key="idex">
            <div class="headline">
              <div class="text">{{ it.title }}</div>
            </div>
            <div class="content">
              <div class="left">
                <div class="it" v-for="(its, idxxx) in it.item" :key="idxxx">
                  {{ its.text }}
                </div>
              </div>
              <div class="right">
                <div class="it">
                  {{ it.con }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">高考不是唯一路径</div>
      <div class="tags">
        <vue-seamless-scroll :data="tag" :class-option="optionLeft" class="seamless-warp2">
          <ul class="item">
            <li class="tag" v-for="(item, index) in tag" :key="index" v-text="item.title"></li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <div class="title">高中选科</div>
      <div class="explain">
        两种科学选科方法，充分了解新高考政策下科目选择所对应的未来高校升学专业，以解决因信息不明而导致的未来填报志愿测选择受限，为学校提供定制化选科服务。
      </div>
      <div class="expBox">
        <div class="explain1">
          <div class="left1">由科目选择专业</div>
          <div class="L">/</div>
          <div class="text" style="text-indent: 6em">
            个人意愿或根据优势学科自行判断得到的高中选科，判断已选定的搭配组合对应的高校专业是否心仪。
          </div>
        </div>
        <div class="explain2">
          <div class="text">
            根据心仪的院校或者专业，获知高中选科的搭配组合，以便确定选科方向。
          </div>
          <div class="L">/</div>
          <div class="right1">由专业选择科目</div>
        </div>
      </div>
      <div class="title">志愿填报</div>
      <div class="VoluntaryReportinBox">
        <div class="left">
          <img src="./images/志愿填报图@2x.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <div class="y">
              <div>
                填写个人信息，根据地区、院校、成绩、专业等细致筛选，明确未来方向。
              </div>
            </div>
            <div class="y">
              <div>
                海量数据库，AI智能筛选，精准匹配，一键生成志愿填报结果。
              </div>
            </div>
          </div>
          <div class="btn">
            <div class="y">
              <div>权威专家组 一对一解析答惑。</div>
            </div>
            <div class="y">
              <div>
                高考志愿填报模拟，提前了解注意事项，熟悉流程，避免失误。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">优势</div>
      <div class="information">
        <div class="list1">
          <div class="top">
            <img src="./images/院校数据@2x.png" alt="" />
          </div>
          <div class="center">院校数据</div>
          <div class="btn">
            全国2856所高校的院校概况、
            特色专业、师资力量、录取数据、招生计划、就业走向、校园全景、招生简章。
          </div>
        </div>
        <div class="list1">
          <div class="top">
            <img src="./images/专业数据@2x.png" alt="" />
          </div>
          <div class="center">专业数据</div>
          <div class="btn">
            全国1200+个专业信息，开设院校、历年录取数据，了解热门专业排行、录取分数，专业内容、核心课程、专业所需特质，未来就业方向。
          </div>
        </div>
        <div class="list1">
          <div class="top">
            <img src="./images/职业数据@2x.png" alt="" />
          </div>
          <div class="center">职业数据</div>
          <div class="btn">
            涵盖300+细分行业数据，具体包括产业概述，市场容量，职业现状，行业需求，职业前景，发展方向等。
          </div>
        </div>
      </div>
      <div class="title">用户案例</div>
      <!-- 轮播图 -->
      <div class="swip">
        <Swipers></Swipers>
      </div>
    </div>
  </div>
</template>

<script>
import Swipers from '@/components/swipers'
export default {
  name: 'EveryTreeAndBushIndex',
  components: {
    Swipers,
  },
  data() {
    return {
      texts: ['Hello', 'World', 'Vue'], speed: 10,
      list1: [
        {
          title: '自我定位',
          con: '六种维度多种分析模型，在线生成精细结果报告，为学生寻找兴趣爱好，未来潜能，为家长了解孩子增设路径，为学校和老师定制化教育提供数据化参考。',
          item: [
            {
              text: '兴趣',
            }, {
              text: '性格',
            }, {
              text: '能力',
            }, {
              text: '价值观',
            }, {
              text: '学力',
            }, {
              text: '环境',
            },
          ]
        },
        {
          title: '多维评测',
          con: '学生自主多维度测试, 全面把握个人学习进度和优势能力，了解学习焦虑的产生因素, 调整心态, 改变学习状态。',
          item: [
            {
              text: '升学路径',
            }, {
              text: '职业探索',
            }
          ]
        },
      ],
      list2: [
        {
          title: '心理测评',
          con: '采用前沿算法，结合长期经验积累构成的数据库和权威心理专家指导，在线生成心理状态报告，大数据常模对照分析，完善学生人物画像，呵护心理健康。',
          item: [
            {
              text: 'SDS',
            }, {
              text: 'SAS',
            }, {
              text: '心理健康诊断',
            }, {
              text: '测验',
            }
          ]
        },
        {
          title: '职业探索',
          con: '全年龄段职业生涯探索途径摆脱人生迷茫。暑期社会体验，实习岗位深入探索，实习方案明晰未来选择，全方位实现个人职业倾向的最优选择。',
          item: [
            {
              text: '职业探索',
            }, {
              text: '职业评估',
            }, {
              text: '职业现状',
            }, {
              text: '行业需求',
            }, {
              text: '职业前景',
            }, {
              text: '发展方向',
            },
          ]
        },
      ],
      tag: [
        {
          title: '高校专项计划'
        }, {
          title: '保送生'
        }, {
          title: '艺术类专业招生'
        }, {
          title: '体育类招生'
        }, {
          title: '强基计划'
        },
        {
          title: '港澳台报考指南'
        },
        {
          title: '高水平艺术团招生'
        },
        {
          title: '高水平运动队招生'
        },
        {
          title: '空军招飞'
        },
      ]
    }
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
.seamless-warp2 {
  overflow: hidden;
  height: 80px;


  .tag {
    font-size: 48px;
    float: left;
    margin-right: 45px;
    font-weight: bold;
  }

  .tag:hover {
    background: #3964E6 !important;
  }
}

.title {
  font-size: 56px;
  font-weight: 500;
  color: #333333;
  margin-top: 140px;
}

.academicAptitudeTest {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;

  .list {
    margin-top: 30px;
    width: 100%;

    .top,
    .btn {
      display: flex;
      justify-content: center;

      .item:nth-last-child(1) {
        margin-left: 80px;
      }

      .item {
        margin-top: 50px;

        .headline {
          width: 560px;
          height: 97px;
          display: flex;
          align-items: center;
          background: #f6f7f9;
          border-radius: 10px;
          margin-bottom: 25px;

          .text {
            width: 229px;
            height: 59px;
            font-size: 42px;
            font-weight: 600;
            color: #333333;
            line-height: 59px;
            border-left: solid 5px #3964e6;
            padding-left: 56px;
          }
        }

        .content {
          display: flex;
          box-sizing: border-box;
          width: 560px;
          background: #f6f9ff;
          border-radius: 10px;
          padding: 22px 60px 20px 61px;

          .left {
            display: flex;
            flex-direction: column;
            font-size: 24px;
            color: #3964e6;
            margin-right: 15px;
            width: 144px;
          }

          .right {
            width: 280px;
            font-size: 20px;
            color: #000000;
            line-height: 28px;
          }
        }
      }
    }
  }

  .tags {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    width: 100%;
    height: 264px;
    background-image: url("./images/长条图@2x.png");
    background-size: 100% 100%;

    .tag {
      cursor: pointer;
      white-space: nowrap;
      animation: slidein infinite;
      height: 80px;
      background: #85a0f0;
      border-radius: 10px;
      padding: 0 20px;
      font-size: 28px;
      color: #ffffff;
      text-align: center;
      line-height: 80px;
      margin-left: 30px;
    }

    @keyframes slidein {
      from {
        transform: translateX(100%);
      }

      to {
        transform: translateX(-100%);
      }
    }
  }

  .explain {
    padding: 0 10%;
    margin-top: 74px;
    text-align: center;
    font-size: 24px;
    color: #000000;
  }

  .expBox {
    padding: 0 10%;
    width: 100%;
    box-sizing: border-box;
  }

  .explain1,
  .explain2 {
    position: relative;
    margin-top: 74px;
    display: flex;
    align-items: end;

    .L {
      opacity: 0;
      width: 214px;
    }

    .left1 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 316px;
      height: 162px;
      background-image: url("./images/科目选择专业@2x.png");
      background-size: 100% 100%;
      text-align: center;
      line-height: 162px;
      font-size: 28px;
      color: #3964e6;
    }

    .right1 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 316px;
      height: 162px;
      background-image: url("./images/专业选择科目@2x.png");
      background-size: 100% 100%;
      text-align: center;
      line-height: 162px;
      font-size: 28px;
      color: #3964e6;
    }

    .text {
      text-indent: 2em;
      line-height: 124px;
      height: 124px;
      font-size: 20px;
      background: #f9f9f9;
      width: 100%;
    }
  }

  .VoluntaryReportinBox {
    margin-top: 102px;
    width: 100%;
    padding: 0 10%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;

    .left {
      width: 495px;
      min-width: 370px;
      margin-right: 50px;
    }

    .right {
      .btn {
        margin-top: 26px;
      }

      .top,
      .btn {
        display: flex;

        .y {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 224px;
          height: 224px;
          background: #748cd6;
          border-radius: 134px;
          font-size: 18px;
          text-align: center;
          margin-right: 30px;
          color: #ffffff;

          div {
            width: 150px;
          }
        }
      }
    }
  }

  .information {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 80px;
    background-image: url("./images/海量数据图@2x.png");
    background-size: 100% 100%;
    width: 100%;
    height: 497px;
    box-sizing: border-box;

    .list1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .top {
        width: 94px;
        height: 72px;
      }

      .center {
        font-size: 38px;
        font-weight: 600;
        color: #333333;
        margin: 27px 0 37px 0;
      }

      .btn {
        width: 241px;
        font-size: 18px;
        color: #333333;
      }
    }
  }

  .swip {
    margin-top: 80px;
    width: 100%;
    height: 590px;
  }
}
</style>