<template>
    <div class="box">
        <div class="kindBox">
            <div class="title">公派留学</div>
            <div class="project">
                <div class="headline">
                    <div class="s"></div>
                    <div>项目介绍</div>
                </div>
                <div class="introduce">
                   为响应人才强国战略和“一带一路”经济发展战略，公派留学项目开展了位于新加坡、澳大利亚、新西兰、马来西亚、俄罗斯、韩国六个国家的本科和研究生留学计划，为中国学子提供海外进修机会，加强中国与世界各国人民之间的了解，促进中国社会主义现代化建设，推动世界和平事业。
                </div>
            </div>
            <div class="project" style="padding-bottom: 0px;">
                <div class="headline">
                    <div class="s"></div>
                    <div>项目优势</div>
                </div>
            </div>
            <div class="advantage">
                <div class="text">QS排名前200院校学习经历，学历含金量高，开拓国际视野。</div>
                <div class="xian"></div>
                <div class="text">30岁以前均可申请，学制短节约更多时间成本。</div>
                <div class="xian"></div>
                <div class="text">每月生活补贴最高可达五千美元，减轻经济压力。</div>
                <div class="xian"></div>
                <div class="text">学成归来，可推荐进入央企、国企、事业编等单位就业。</div>
            </div>
            <div class="list">
                <div class="left">
                    <div class="top">国家选择</div>
                    <div class="btn">
                        <span>
                            新加坡
                        </span>
                        <span>
                            澳大利亚
                        </span>
                        <span>
                            新西兰
                        </span>
                        <span>
                            马来西亚
                        </span>
                        <span>
                            俄罗斯
                        </span>
                        <span>
                            韩国
                        </span>
                    </div>
                </div>
                <div class="right">
                    <div class="top">专业限定</div>
                    <div class="btn">
                        <div class="le">
                            <div class="t">经管类</div>
                            <div class="b">经济学类专业</div>
                            <div class="b">财政学类专业</div>
                            <div class="b">金融学类专业</div>
                            <div class="b">经济与贸易类专业</div>
                            <div class="b">管理学类专业</div>
                            <div class="b">工商管理类专业</div>
                        </div>
                        <div class="ri">
                            <div class="t">理工类</div>
                            <div class="b">电子信息科学类专业</div>
                            <div class="b">数化类专业</div>
                            <div class="b">工程类专业</div>
                            <div class="b">环境资源类专业</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project">
                <div class="headline">
                    <div class="s"></div>
                    <div>世界名校</div>
                </div>
                <div class="schoolList">
                    <div class="item">
                        <div class="top">
                            <img src="./images//墨尔本@2x.png" alt="">
                        </div>
                        <div class="btn">墨尔本大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images/悉尼@2x.png" alt="">
                        </div>
                        <div class="btn">悉尼大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//奥克兰@2x.png" alt="">
                        </div>
                        <div class="btn">奥克兰大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//马来西亚@2x.png" alt="">
                        </div>
                        <div class="btn">马来西亚国立大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//澳大利亚@2x.png" alt="">
                        </div>
                        <div class="btn">澳大利亚国立大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//新南@2x.png" alt="">
                        </div>
                        <div class="btn">新南威尔士大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//莫斯科@2x.png" alt="">
                        </div>
                        <div class="btn">莫斯科国立大学</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <img src="./images//延世@2x.png" alt="">
                        </div>
                        <div class="btn">延世大学</div>
                    </div>
                </div>
            </div>
            <div class="serviceBox">
                <div class="title3" style="font-size: 56px;margin-top: 17px;">服务</div>
                <div class="service">
                    <div class="text">申请流程规范
                    </div>
                    <div class="text">课程专业辅导
                    </div>
                    <div class="text">面试实景训练
                    </div>
                    <div class="text">出国后续服务
                    </div>
                </div>
            </div>
            <div class="title">香港地区升学计划</div>
            <div style="font-size: 24px;margin:80px 0 ;">家长申请香港身份，孩子享有轻松升学</div>
            <div class="project">
                <div class="headline">
                    <div class="s"></div>
                    <div>身份优势</div>
                </div>
                <div class="identity">
                    <div class="left">
                        <div class="title2">家长</div>
                        <div class="ts">税务优化</div>
                        <div class="ts">获得在港投资的资格</div>
                        <div class="ts">享有亚洲顶尖医疗资源</div>
                        <div class="ts">自由出入境免签国家</div>
                    </div>
                    <div class="center">
                        <img src="./images/ff8466a7f73b49d7ed92e6ac133f43e3b2b8cb2317c12-CEVdA1@2x.png" alt="">
                    </div>
                    <div class="right">
                        <div class="title2">子女</div>
                        <div class="ts">HKDSE（香港中学文凭考试），获得内地和部分海外高校的认可，拥有更广泛的择校范围。</div>
                        <div class="ts">华侨联考，考试难度和内地录取分数线较低，竞争人数少。</div>
                        <div class="ts">在港接受高等教育的学费获得政府补助，高达60万港币。</div>
                    </div>
                </div>
            </div>
            <div class="project" style="background: #F9F9F9;">
                <div class="headline" style="margin-top: 40px;">
                    <div class="s"></div>
                    <div>4种途径</div>
                </div>
                <div class="introduce">
                    HKDSE（香港中学文凭考试）专业辅导，提供线上线下课程辅导，助力学生斩获高分。
                </div>
                <div class="approach" style="margin-top: 70px;">
                    <div class="approachs">香港菁英计划</div>
                    <div class="approachs">香港专才计划</div>
                </div>
                <div class="approach">
                    <div class="approachs">香港优才计划</div>
                    <div class="approachs">香港高才通</div>
                </div>
            </div>
            <div class="title">其他留学项目</div>
            <div class="project ocsbox">
                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">泰国</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">泰国皇家园大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">泰国武里南皇家大学
                        </div>
                    </div>
                </div>

                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">乌克兰</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">乌克兰基辅国立建筑大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">乌克兰沃伦国立大学
                        </div>
                    </div>
                </div>

                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">芬兰</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">芬兰免学费高中项目</div>
                    </div>
                </div>

                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">马来西亚</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">马来西亚国立大学</div>
                    </div>
                </div>
                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">新加坡预科班合作院校</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">新加坡管理学院
                            (Singapore Institute of Management)暨合作大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">伦敦城市大学(London Metropolitan University)
                            暨新加坡斯坦福特学院(Stanfort Academy）</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">新加坡楷博(Kaplan)高等教育学院暨合作大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">澳大利亚詹姆斯库克大学新加坡校区
                            (JCU Singapore)</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">新加坡PSB学院暨合作大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">澳大利亚科廷大学新加坡校区</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">新加坡管理发展学院(Management
                            Development Institute Singapore)暨合作大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">英国伦敦音乐学院暨新加坡莱佛士音乐学院</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">莎顿国际学院暨合作大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">莱佛士设计学院(Raffles College of Higher
                            Education)暨合作大学</div>
                    </div>
                </div>

                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">美国高校奖学金项目</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州名校直通车</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州大学伯克利分校</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州大学洛杉矶分校</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">南加州大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">纽约大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州大学圣芭芭拉分校</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州大学尔湾分校（欧文）</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">南加州大学</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">加州大学圣迭戈分校（圣地亚哥）
                            加州大学戴维斯分校</div>
                    </div>
                </div>
                <div class="headline ocs" style="margin-top: 40px;">
                    <div style="display: flex;">
                        <div class="s"></div>
                        <div style="font-size: 36px; margin-bottom: 40px;">新加坡快捷本科课程</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">澳大利亚mes Cook大学(澳大利亚詹姆斯库克大学）</div>
                    </div>
                    <div class="schoolss">
                        <div class="chTex">Yale-NUS College
                            耶鲁一国大学院</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'EveryTreeAndBushIndex',
    data() {
        return {
        }
    },

    mounted() {

    },

    methods: {

    },
};
</script>
  
<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    height: 100%;
}

.kindBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    background: #FFFFFF;

    .title {
        margin-top: 140px;
        font-size: 56px;
        font-weight: 500;
    }


    .project {
        min-width: 100%;
        padding: 0 10%;
        box-sizing: border-box;
        padding-bottom: 80px;

        .headline {
            width: 184px;
            height: 50px;
            font-size: 36px;
            color: #333333;
            text-align: center;
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            .s {
                width: 6px;
                height: 40px;
                background: #3964E6;
                margin-right: 20px;
            }

        }

        .introduce {
            margin-left: 26px;
            font-size: 24px;
        }


    }

    .advantage {
        min-width: 100%;
        min-height: 195px;
        background: #F4F7FF;
        padding: 0 360px 0 386px;
        font-size: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .xian {
            width: 1px;
            height: 195px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #3964E6 53%, rgba(255, 255, 255, 0) 100%);
            border-radius: 1px;
            margin: 0 17px;
        }

        .text {
            width: 259px;
            height: 103px;
        }
    }

    .list {
        min-width: 100%;
        padding: 0 386px;
        box-sizing: border-box;
        display: flex;
        margin-top: 80px;
        margin-bottom: 80px;

        .left {
            width: 469px;
            min-width: 200px;
            margin-right: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .top {
                text-align: center;
                line-height: 130px;
                width: 469px;
                height: 130px;
                font-size: 42px;
                color: #FFFFFF;
                font-weight: 600;
                background: linear-gradient(108deg, #3964E6 0%, #A4A8EE 100%);
            }

            .btn {
                padding-left: 93px;
                box-sizing: border-box;
                padding-top: 42px;
                width: 469px;
                min-height: 460px;
                background: #F9F9F9;
                display: flex;
                flex-wrap: wrap;

                span {
                    box-sizing: border-box;
                    margin-right: 59px;
                    font-size: 32px;
                }
            }
        }

        .right {
            box-sizing: border-box;
            flex: 1;
            min-width: 600px;
            min-height: 460px;

            .top {
                text-align: center;
                line-height: 130px;
                flex: 1;
                height: 130px;
                font-size: 42px;
                color: #FFFFFF;
                font-weight: 600;
                background: linear-gradient(108deg, #3964E6 0%, #A4A8EE 100%);
            }

            .btn {
                padding: 42px 26px 25px 64px;
                box-sizing: border-box;
                padding-top: 42px;
                min-height: 460px;
                background: #F9F9F9;
                display: flex;
                justify-content: space-between;
                font-size: 28px;

                .le,
                .ri {
                    .t {
                        font-weight: bold;
                        font-size: 32px;
                        margin-bottom: 30px;
                    }

                    .b {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }

    .schoolList {
        display: flex;
        flex-wrap: wrap;
        margin-left: 26px;
        font-size: 24px;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 25%;
            margin-top: 45px;

            .top {
                min-height: 100px;
                height: 158px;

                img {
                    min-width: 25px;
                    width: 154PX;
                    height: auto;
                }
            }

            .btn {
                margin-top: 20px;
                font-size: 14px;
            }
        }
    }

    .serviceBox {
        width: 100%;
        height: 209px;
        background-image: url('./images/位图@2x.png');
        background-size: 100% 100%;
        font-size: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 26px;

        .service {
            margin-top: 50px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 26px;
            font-weight: bold;
        }

    }

    .identity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 26px;

        .left {
            width: 240px;

            .title2 {
                font-size: 36px;
            }

            .ts {
                margin-top: 45px;
                font-size: 24px;
            }
        }

        .center {
            box-sizing: border-box;
            width: 430px;
            margin: 0 60px;
            margin-left: 175px;
        }

        .right {
            width: 359px;

            .title2 {
                font-size: 36px;
            }

            .ts {
                margin-top: 24px;
                font-size: 24px;
            }
        }
    }

    .approach {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .approachs {
            width: 365px;
            margin: 0 3% 50px 0;
            height: 130px;
            background: #FFFFFF;
            font-size: 36px;
            color: #3964E6;
            text-align: center;
            line-height: 130px;
            font-weight: bold;
        }
    }

    .ocsbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .ocs {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            // align-items: baseline;
            font-size: 24px;

            .schoolss {
                margin-bottom: 35px;
                text-align: left;
            }
        }
    }
}
</style>