<template>
  <div class="content">
    <!-- 分类导航 -->
    <div class="typenav">
      <TypeNav />
    </div>
    <div class="box">
      <div class="right">
        <!--路由出口-->
        <transition name="el-fade-in-linear">
          <div class="main-content">
            <router-view></router-view>
          </div>
        </transition>
      </div>
    </div>
    <!-- 底部导航 -->
    <div class="typenav">
      <Particulars />
    </div>
  </div>
</template>

<script>
import TypeNav from "@/components/TypeNav"
import Particulars from "@/components/Particulars"
export default {
  name: 'OCRrecognitionIndex',
  components: { TypeNav, Particulars },
  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
</style>