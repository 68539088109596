<template>
    <div id="app">
        <!-- <div class="SwiperBox" @mouseenter.stop="MouseFun('移入')" @mouseleave.stop="MouseFun('移出')"> -->
        <div class="SwiperBox">
            <!-- 图片 -->
            <div :class="['imgCss', ShowImg == index ? 'ShowCss' : '']" v-for="(item, index) in imgList" :key="index">
                <div class="" style="margin-right: 20px;">
                    <div class="text">
                        {{ item.text }}</div>
                    <div class="explain">
                        {{ item.explain }}
                    </div>
                </div>
                <div class="imgs">
                    <img :src="item.imgUrl" />
                </div>
            </div>
            <!-- 下方指示点容器 -->
            <div class="instBox">
                <div v-for="(item, index) in imgList.length" :key="index" @click="ShowImg = index"
                    :class="['inst', ShowImg == index ? 'instActv' : '']"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OCRrecognitionIndex',
    props: {
    },
    data() {
        return {
            imgList: [
                {
                    imgUrl: "http://www.ycymedu.com/img/sygh.png", text: '济南市深泉外国语学校', explain: '六纬生涯规划系统由集团下属公司定制开发。系统由职业方向测量、新高考选科指导、多维发展、志愿填报、综评录取、心理测量等模块组成，主要面向高中学段应用，其中心理测量模块在初中应用。'
                },
            ],
            ShowImg: 0,  // 表示当前显示的图片
            flag: true, // 用来节流防止重复点击
            start: null, // 自动执行下一张定时器
        }
    },
    mounted() {
        // this.setTimeoFun()
    },
    methods: {
        // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
        MouseFun(type) {// 停止定时器            // 重新执行定时器
            type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
        },
        setTimeoFun() {
            this.start = setInterval(() => {
                this.NextFun()
            }, 2000)
        },
        // 上一张
        PrevFun() {
            if (this.ShowImg !== 0) {
                this.ShowImg--
            } else {
                this.ShowImg = this.imgList.length - 1
            }
        },
        // 下一张
        NextFun() {
            if (this.ShowImg !== this.imgList.length - 1) {
                this.ShowImg++
            } else {
                this.ShowImg = 0
            }
        },
    }
};
</script>
<style lang="less" scoped>
.text {
    width: 498px;
    font-size: 38px;
    color: #333333;
    text-align: left;
}

.explain {
    margin-top: 29px;
    width: 550px;
    font-size: 20px;
}

.imgs {
    width: 655px;
    height: 305px;
}


.imgs {
    width: 655px;
    height: 305px;
}

/* 图片容器样式 */
.SwiperBox {
    position: relative;
    width: 100%;
    height: 385px;
    box-sizing: border-box;
    cursor: pointer;
}

/* 图片默认样式 */
.imgCss {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.8s;
    display: flex;
    align-items: center;
    justify-content: center;
    /* 淡入淡出过渡时间 */
}

/* 图片选中样式(继承上方默认样式) */
.ShowCss {
    opacity: 1;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(109, 109, 109, 0.445);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.3;
}

.rightBtn:hover {
    opacity: 1 !important;
}

.leftBtn:hover {
    opacity: 1 !important;
}

.leftBtn {
    left: 5%;
}

.rightBtn {
    right: 5%;
}

/* 下方指示器盒子 */
.instBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
    display: flex;
}

/* 小圆点 */
.inst {
    width: 60px;
    height: 10px;
    background: #EFF4FF;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
}

.inst:last-child {
    margin-right: 0px;
}

.instActv {
    width: 120px;
    height: 10px;
    background: #3964E6;
    border-radius: 5px;
}

#app {
    width: 100%;
}
</style>>