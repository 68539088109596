<template>
    <div class="box">
        <div class="sceneBox">
            <div class="title">产品场景</div>
            <div class="scene">
                <img src="./images/8ede64c9b2129285d9c82d73ee2bb11baf32cbb84a100-S68BP0_fw1200@2x.png" alt="">
            </div>
        </div>
        <div class="schemeBox">
            <div class="titleBox">
                <div class="title">产品方案</div>
            </div>
            <div class="explain">以“减负提效、体验至上”的产品设计理念，围绕备、教、练、考、评、监教学全场景应用闭环，提供易用实用的教学工具，实现线上与线下混合式教学环境，助力学校教育信息化的常态化落地应用。
            </div>
            <div class="scheme">
                全场景数据采集与分析：教学评价数据、行为数据、师生交互数据、学生画像、教师画像 ······
            </div>
            <div class="content">
                <div class="crowdBox">
                    <div class="crowd">
                        <div class="icon">
                            <img src="./images/管理员@2x.png" alt="">
                        </div>
                        <div class="text">管理者</div>
                    </div>
                    <div class="crowd">
                        <div class="icon">
                            <img src="./images/服务台@2x.png" alt="">
                        </div>
                        <div class="text">教职工</div>
                    </div>
                    <div class="crowd">
                        <div class="icon">
                            <img src="./images/家长@2x.png" alt="">
                        </div>
                        <div class="text">家长</div>
                    </div>
                    <div class="crowd">
                        <div class="icon">
                            <img src="./images/学生@2x.png" alt="">
                        </div>
                        <div class="text">学生</div>
                    </div>
                </div>
                <div class="settingBox">
                    <div class="title1">
                        <div class="s"></div>
                        <div class="title">应用场景</div>
                    </div>
                    <div class="setting">
                        <div class="top">
                            <div class="item">
                                <div class="caption">备课</div>
                                <div class="text">资源共享</div>
                                <div class="text">自建资源</div>
                                <div class="text">特色校本库</div>
                                <div class="text">个性备课</div>
                            </div>
                            <div class="item">
                                <div class="caption">课堂</div>
                                <div class="text">智能笔</div>
                                <div class="text">智能笔</div>
                                <div class="text">互动课堂</div>
                                <div class="text">微课录制</div>
                            </div>
                            <div class="item">
                                <div class="caption">作业</div>
                                <div class="text">在线练习</div>
                                <div class="text">自定义作业布置</div>
                                <div class="text">在线批改</div>
                                <div class="text">学情报告</div>
                            </div>
                            <div class="item">
                                <div class="caption">考试</div>
                                <div class="text">自由组卷</div>
                                <div class="text">试卷上传</div>
                                <div class="text">诊断学习</div>
                                <div class="text">多维考情分析</div>
                            </div>
                        </div>
                        <div class="center">
                            <div class="left">
                                <div class="t">评价</div>
                                <div class="b">
                                    <div class="f">
                                        <div>积累错题集</div>
                                        <div>错题跟进练习</div>
                                    </div>
                                    <div class="r">
                                        <div>精准化教学</div>
                                        <div>微视频答疑</div>
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="t">监测</div>
                                <div class="b">
                                    <div class="f">
                                        <div>个性化分析</div>
                                        <div>过程性数据采集</div>
                                    </div>
                                    <div class="r">
                                        <div>大数据分析</div>
                                        <div>质量检测</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn">
                            <div class="bIt">“开放”平台
                            </div>
                            <div class="bIt">“全面”应用
                            </div>
                            <div class="bIt">“学科”融合
                            </div>
                            <div class="bIt">“流畅” 好用
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="functionalityBox">
            <div class="titleBox">
                <div class="title">特色功能</div>
            </div>
        </div>
        <div class="advantageBox">
            <div class="titleBox">
                <div class="title">核心优势</div>
            </div>
            <div class="advantage">
                <div class="item">
                    <div class="emphasis">特色校本库</div>
                    <div class="state">构建同步资源、全学科资源中心</div>
                    <div class="state">PPT备课插件不改变老师备课习惯 </div>
                    <div class="state">学情即时反馈：课前预习数据、课后作业反馈</div>
                    <div class="state">个人备课和集体备课双备课模式</div>
                </div>
                <div class="item">
                    <div class="emphasis">AI智慧课堂</div>
                    <div class="state">随堂检测即时反馈，课堂互动、分组学习，提高课堂教学效率</div>
                    <div class="state">PPT备课插件不改变老师备课习惯 </div>
                    <div class="state">纸笔答题、师生互动</div>
                </div>
                <div class="item">
                    <div class="emphasis">个性化学习</div>
                    <div class="state">落实国家双减政策，实现科学设计分层、个性化作业</div>
                    <div class="state">专属错题本，帮助学生个性化学习，提高学习质量 </div>
                </div>
                <div class="item">
                    <div class="emphasis">尊重用户习惯</div>
                    <div class="state">不改变学生纸笔作答习惯</div>
                    <div class="state">学校原有信息化基础设备 </div>
                    <div class="state">极大的降低学生终端成本</div>
                </div>
                <div class="item">
                    <div class="emphasis">智能化作业批改</div>
                    <div class="state">无需联网，离线答题，自动采集数据</div>
                    <div class="state">快速采集数据，支持各种大小答题卡 </div>
                    <div class="state">客观提系统智能批阅，学情即时反馈</div>
                    <div class="state">主观题随时随地在线批改，笔记留痕</div>
                </div>
                <div class="item">
                    <div class="emphasis">自动累计错题集</div>
                    <div class="state">为老师生成班级错题集</div>
                    <div class="state">为学生生成个人错题集 </div>
                    <div class="state">错题集下载、打印，便于订正</div>
                    <div class="state">错题再跟进练习，个性化学习</div>
                </div>
                <div class="btn">
                    <div class="emphasis1">大数据智能分析</div>
                    <div class="btoa">
                        <span>大数据分析，支撑作业高效讲评</span>
                        <span>不同维度诊断个性问题和变化趋势</span>
                    </div>
                </div>
            </div>
        </div>




        <div class="SwiperBox">
            <div class="titleBox">
                <div class="title">用户案例</div>
            </div>
            <div class="Swiper">
                <swiperThree />
            </div>
        </div>
    </div>
</template>
  
<script>
import swiperThree from "@/components/swiperThree";
export default {
    name: 'EveryTreeAndBushIndex',
    components: {
        swiperThree
    },
    data() {
        return {
        }
    },

    mounted() {

    },

    methods: {

    },
};
</script>
  
<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    height: 100%;
    color: #333333;
}

.sceneBox {
    padding: 0 360px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-size: 56px;
        margin-top: 140px;
    }

    .scene {
        margin-top: 89px;
        width: 1048px;
        height: 620px;
    }
}

.schemeBox {

    .titleBox {
        padding: 0 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;

        .title {
            font-size: 56px;
            margin-top: 140px;
        }
    }

    .explain {
        padding: 80px 360px 40px 360px;
        background: #FFFFFF;
        font-size: 24px;
    }

    .scheme {
        padding: 0 360px;
        text-align: left;
        line-height: 130px;
        color: #FFFFFF;
        font-size: 28px;
        height: 130px;
        background: linear-gradient(108deg, #3964E6 0%, #A4A8EE 100%);
    }

    .content {
        background: #FFFFFF;
        padding: 0 10%;

        .crowdBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 40px;
            padding-bottom: 80px;

            .crowd {
                width: 23%;
                height: 240px;
                background: #F9F9F9;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;

                .icon {
                    width: 90px;
                    height: auto;
                }

                .text {
                    color: #3964E6;
                    font-size: 24px;
                    font-weight: bold;
                }
            }
        }

        .settingBox {
            .title1 {
                display: flex;
                align-items: center;
                padding-bottom: 40px;

                .s {
                    width: 6px;
                    height: 40px;
                    background: #3964E6;
                }

                .title {
                    font-size: 36px;
                    padding-left: 26px;
                }
            }

            .setting {
                .top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 25px;

                    .item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 23%;
                        height: 307px;
                        background: #f9f9f9;
                        border-radius: 10px;

                        .caption {
                            margin-top: 36px;
                            margin-bottom: 10px;
                            font-size: 24px;
                            color: #3964E6;
                            font-weight: bold;
                        }

                        .text {
                            margin-top: 15px;
                            font-size: 24px;
                        }
                    }
                }

                .center {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 25px;

                    .left,
                    .right {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 587px;
                        height: 207px;
                        background: #f9f9f9;
                        border-radius: 10px;
                        padding: 39px 68px 38px 80px;
                        box-sizing: border-box;

                        .t {
                            font-size: 24px;
                            color: #3964E6;
                            font-weight: bold;
                        }

                        .b {
                            width: 100%;
                            font-size: 24px;

                            .f,
                            .r {
                                display: flex;
                                justify-content: space-between;

                                div {
                                    margin-top: 14px;
                                }
                            }
                        }
                    }

                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 140px;

                    .bIt {
                        width: 23%;
                        height: 90px;
                        background: #3A64E6;
                        border-radius: 10px;
                        font-size: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 90px;
                    }
                }
            }
        }
    }
}

.functionalityBox {
    height: 725px;
    background-image: url('./images/编组@2x.png') !important;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #F8FAFE;

    .titleBox {
        padding: 0 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 56px;
            margin-top: 62px;
        }
    }
}

.advantageBox {
    padding: 0 10% 137px 10%;
    background: #f9f9f9;

    .titleBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 56px;
            margin-top: 140px;
            margin-bottom: 80px;
        }
    }

    .advantage {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: 46%;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 25px;
            box-sizing: border-box;
            padding: 41px 24px 0 35px;

            .emphasis {
                font-weight: bold;
                font-size: 24px;
                color: #3964E6;
                margin-bottom: 43px;
            }

            .state {
                margin-bottom: 20px;
                font-size: 24px;
            }
        }

        .btn {
            width: 100%;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 41px 195px 0 35px;

            .emphasis1 {
                font-weight: bold;
                font-size: 24px;
                color: #3964E6;
                margin-bottom: 43px;
            }

            .btoa {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                font-size: 24px;
            }
        }
    }
}




.SwiperBox {
    background: #FFFFFF;
    padding: 0 360px;

    .titleBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            box-sizing: border-box;
            padding-top: 90px;
            font-size: 56px;
            margin-bottom: 80px;
        }
    }

    .Swiper {
        width: 100%;
        height: 590px;
    }
}
</style>