<template>
    <div class="box">
        <div class="kindBox">
            <div class="title">硕士研究生</div>
            <div class="difficulty">
                <div class="difficultyLeft">
                    <div class="text">
                        <div class="s"></div>
                        <div>难点</div>
                    </div>
                    <img src="./images/fcddf228d399187c0230fc76d6540c7f77c2842546029-fo31OE@2x.png" alt="">
                </div>
                <div class="difficultyRight">
                    <div class="item">
                        <div class="tit">
                            <div class="y"></div>
                            <div class="text">考研无规划</div>
                        </div>
                        <div class="content">跨专业准备事项不明确，备考专业要求不了解，院校选拔制度不清晰</div>
                    </div>
                    <div class="item">
                        <div class="tit">
                            <div class="y"></div>
                            <div class="text">备考无体系</div>
                        </div>
                        <div class="content">复习资料梳理杂乱，往年资源真伪不明，上岸笔记漫天要价</div>
                    </div>
                    <div class="item">
                        <div class="tit">
                            <div class="y"></div>
                            <div class="text">功课进度慢
                            </div>
                        </div>
                        <div class="content">自律性差，拖延严重，无法按时完成学习计划</div>
                    </div>
                    <div class="item">
                        <div class="tit">
                            <div class="y"></div>
                            <div class="text">复试经验少</div>
                        </div>
                        <div class="content">择校调剂选择没有头绪，复试面试技巧不明</div>
                    </div>
                </div>
            </div>
            <div class="project">
                <div class="headline">
                    <div class="s"></div>
                    <div>择校规划</div>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="t">
                            <div class="caption">明确个人意愿</div>
                            <div class="text">明确学生对专业，学校，地区的倾向，完成个人意愿调查；评估本科学习成绩、专业能力，综合获得个人能力评价。</div>
                        </div>
                        <div class="b">
                            <div class="caption">一对一择校服务</div>
                            <div class="text">权威专家组，一对一解析答惑，敲定专业填报细节。</div>
                        </div>
                    </div>
                    <div class="center">
                        <img src="./images/1ab170f05d43304c36c5c97bcb4c111920004eca1b667-DKUo35_fw1200@2x.png" alt="">
                    </div>
                    <div class="right">
                        <div class="t">
                            <div class="caption">考研测评工具</div>
                            <div class="text">实时把控最新考研政策、往年报录比、往年国家线或学校线，是否自主命题等院校信息智能匹配，推荐择校方案。</div>
                        </div>
                        <div class="b">
                            <div class="caption">定制学习计划</div>
                            <div class="text">全方位定制个性化考研计划和学习进度要求，辅助学生备考体系搭建。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="serve">
                <div class="headline">
                    <div class="s"></div>
                    <div>服务优势</div>
                </div>
                <div class="ig">
                    <img src="./images/ef10c841af4cb9bd340497b6a1de91b48124a89f13ce1c-GqCoBF_fw1200@2x.png" alt="">
                </div>
                <div class="content">
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/规划@2x.png" alt="">
                            </div>
                            <div>全程学习规划服务</div>
                        </div>
                        <div class="btn">科学运用“分数目标分解法”规划学生的学习时间和重点，合理分配学习精力，一战成功。</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/labels@2x.png" alt="">
                            </div>
                            <div>个性化分层教学体系</div>
                        </div>
                        <div class="btn">针对学生学科基础和学习性，适时进行分层教学，帮助学生查漏补缺，完成各阶段的学习目标。</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/w_教学@2x.png" alt="">
                            </div>
                            <div>专业课精准教学</div>
                        </div>
                        <div class="btn">参照学生报考计划锁定专业课程，名校名师解答疑惑，让学生充分了解学校考试的侧重点，精准定位专校专题。</div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/bianji@2x.png" alt="">
                            </div>
                            <div>在线刷题平台</div>
                        </div>
                        <div class="btn">
                            根据往年考题、难题，学生易错题，频错题，智能化推送同类型考题，精准定制练习册。
                            <p>数字端灵活练习，随时随地，充分利用碎片化时间，完善知识体系。</p>
                            <p>考场模拟，熟悉流程，助力学生考场发挥稳定。</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/图书@2x.png" alt="">
                            </div>
                            <div>考研基地</div>
                        </div>
                        <div class="btn">
                            半封闭式管理，集训式服务，贴心督导关心学生的每日学习计划，日常生活起居，保证学生良好的学习状态。
                            <p>24小时，wifi全天开放，基础设施完善，学习氛围浓厚，帮助学生实现劳逸结合，提升学习效率。</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="icon">
                                <img src="./images/材料指导@2x.png" alt="">
                            </div>
                            <div>复试指导</div>
                        </div>
                        <div class="btn">复试流程一览，历年体系化资料共享，面试场景模拟，专业老师提供针对性指导。
                            <p>实时同步调剂院校名额信息，结合学生初始成绩，科学调整报考方向，抚平学生焦虑。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doctor">
                <div class="title">博士研究生</div>
                <div class="docBox">
                    <div class="item">
                        <div class="images"><img src="./images/编组 3备份@2x.png" alt=""></div>
                        <div class="tit">职业生涯规划</div>
                        <div class="attractor">大数据精准测评，锚定未来学术道路方向，合理规划学术路径和职业生涯。</div>
                    </div>
                    <div class="item">
                        <div class="images"><img src="./images/编组 4备份@2x.png" alt=""></div>
                        <div class="tit">学术领域支持</div>
                        <div class="attractor">对接学科专家，聚焦研究方向，互动答疑，综合提升学术能力。</div>
                    </div>
                    <div class="item">
                        <div class="images"><img src="./images/编组 3备份 2@2x.png" alt=""></div>
                        <div class="tit">课题场景落地</div>
                        <div class="attractor">积极提供企业参访服务和行业实践机会，落地理论知识，全面认知社会需要。</div>
                    </div>
                    <div class="item">
                        <div class="images"><img src="./images/编组 4备份 2@2x.png" alt=""></div>
                        <div class="tit">跨学科前沿交流</div>
                        <div class="attractor">实力打造学术交流平台，实时统筹国内外学术会议资讯，分享讨论前沿研究成果，营造丰富个人思维的环境。</div>
                    </div>
                </div>
                <div class="title">用户案例/合作学校</div>
                <div class="caseBox">
                    <!-- 轮询标签 -->
                    <vue-seamless-scroll :data="tag" :class-option="optionLeft" class="seamless-warp2">
                        <ul class="item">
                            <li v-for="item in tag" :key="item" v-text="item.title"></li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'EveryTreeAndBushIndex',
    data() {
        return {
            tag: [
                {
                    title: '杭州塘湾教育发展公司（浙江工商大学杭州商学院下属公司）'
                }, {
                    title: '衢州学院'
                }, {
                    title: '温州大学'
                }, {
                    title: '温州肯恩大学'
                }, {
                    title: '浙江中医药大学人文与管理学院'
                }
            ]
        }
    },
    computed: {
        optionLeft() {
            return {
                direction: 2,
                limitMoveNum: 2
            }
        }
    },
    mounted() {

    },

    methods: {

    },
};
</script>
  
<style lang="less" scoped>
.seamless-warp2 {
    overflow: hidden;
    height: 180px;

    ul.item {

        li {
            font-size: 48px;
            float: left;
            margin-right: 45px;
            font-weight: bold;
        }
    }
}

img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    height: 100%;
}

.kindBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    background: #FFFFFF;

    .title {
        margin-top: 140px;
        font-size: 56px;
        font-weight: 500;
    }

    .difficulty {
        display: flex;
        margin-top: 61px;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 89px;
        padding: 0 10%;
        width: 100%;
        box-sizing: border-box;

        .difficultyLeft {
            position: relative;
            width: 575px;
            height: 774px;

            .text {
                width: 100%;
                position: absolute;
                top: 19px;
                left: 0;
                font-size: 36px;
                line-height: 50px;
                display: flex;
                align-items: center;

                .s {
                    width: 6px;
                    height: 40px;
                    background: #3964E6;
                    margin-right: 20px;
                }
            }
        }

        .difficultyRight {
            margin-left: 158px;

            .item {
                font-size: 24px;
                margin-bottom: 73px;

                .tit {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                }

                .y {
                    width: 20px;
                    height: 20px;
                    background: #3964E6;
                    border-radius: 50%;
                    margin-right: 11px;
                }

                .content {
                    margin-left: 31px;
                }
            }
        }
    }

    .project {
        width: 100%;
        padding: 0 10%;
        box-sizing: border-box;
        padding-bottom: 80px;

        .headline {
            width: 184px;
            height: 50px;
            font-size: 36px;
            color: #333333;
            text-align: center;
            display: flex;
            align-items: center;
            margin-bottom: 96px;

            .s {
                width: 6px;
                height: 40px;
                background: #3964E6;
                margin-right: 20px;
            }

        }

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                width: 363px;

                .t,
                .b {
                    margin-top: 13px;

                    .caption {
                        font-size: 24px;
                        text-align: end;
                        margin-bottom: 13px;
                    }

                    .text {
                        font-size: 20px;
                        text-align: inherit;
                    }
                }
            }

            .center {
                width: 346px;
                height: 208px;
                margin: 0 64px;
            }

            .right {
                width: 363px;


                .t,
                .b {
                    margin-top: 13px;

                    .caption {
                        font-size: 24px;
                        margin-bottom: 13px;
                    }

                    .text {
                        font-size: 20px;
                        text-align: inherit;
                    }
                }
            }
        }
    }

    .serve {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10%;
        box-sizing: border-box;
        padding-bottom: 80px;
        background: #F4F7FF;
        position: relative;

        .headline {
            width: 184px;
            height: 50px;
            font-size: 36px;
            color: #333333;
            text-align: center;
            display: flex;
            align-items: center;
            margin-bottom: 96px;
            margin-top: 38px;
            margin-bottom: 30px;

            .s {
                width: 6px;
                height: 40px;
                background: #3964E6;
                margin-right: 20px;
            }

        }

        .ig {
            position: absolute;
            top: 0;
            right: 0;
            width: 910px;
            height: 727px;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                box-sizing: border-box;
                margin: 25px;
                width: 26%;
                padding: 25px;
                background: #FCFDFF;
                font-size: 20px;
                z-index: 999;

                .top {
                    display: flex;
                    align-items: center;
                    font-weight: 900;
                    margin-bottom: 15px;
                    font-size: 20px;
                    color: #3964E6;

                    .icon {
                        height: 30px;
                        margin-right: 19px;
                    }
                }

                .btn {}
            }
        }
    }

    .doctor {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;

        .docBox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 10%;

            .item {
                width: 586px;
                display: flex;
                flex-direction: column;
                margin-bottom: 80px;

                .images {
                    width: 586px;
                }

                .tit {
                    margin-left: 10%;
                    font-size: 36px;
                    color: #333333;
                    margin-top: 45px;
                    margin-bottom: 35px;
                }

                .attractor {
                    margin-left: 10%;
                    width: 586px;
                    font-size: 24px;
                }
            }
        }

        .caseBox {
            margin-top: 132px;
            margin-bottom: 140px;
            width: 100%;
            cursor: pointer;

            li {
                padding: 56px 36px;
                height: 180px;
                background: #F6F9FF;
                box-shadow: 0px 8px 20px 0px rgba(192, 192, 192, 0.06);
                border-radius: 10px;
            }
        }
    }
}
</style>