<template>
    <div class="box">
        <div class="sceneBox">
            <div class="title">适配场景</div>
            <div class="scene">
                <div class="item">
                    <div class="img">
                        <img src="./images/场景1@2x.png" alt="">
                    </div>
                    <div class="text">智慧体育教学区</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="./images/场景2@2x.png" alt="">
                    </div>
                    <div class="text">智慧操场</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="./images/场景3@2x.png" alt="">
                    </div>
                    <div class="text">智慧跳绳角</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="./images/场景4@2x.png" alt="">
                    </div>
                    <div class="text">自助锻炼区</div>
                </div>
            </div>
        </div>
        <div class="projectBox">
            <div class="title">支持项目</div>
            <div class="project">
                <div class="item">
                    <div class="icon">
                        <img src="./images/50米跑@2x.png" alt="">
                    </div>

                    <div class="text">50米跑</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/800米跑@2x.png" alt="">
                    </div>

                    <div class="text">800米跑</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/往返跑@2x.png" alt="">
                    </div>

                    <div class="text">往返跑</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/跳远@2x.png" alt="">
                    </div>

                    <div class="text">立定跳远</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/坐位体前屈@2x.png" alt="">
                    </div>

                    <div class="text">坐位体前屈</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/仰卧起坐@2x.png" alt="">
                    </div>

                    <div class="text">仰卧起坐</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/引体向上@2x.png" alt="">
                    </div>

                    <div class="text">引体向上</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/跳绳@2x.png" alt="">
                    </div>

                    <div class="text">跳绳</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/身高体重@2x.png" alt="">
                    </div>

                    <div class="text">身高/体重</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/肺活量@2x.png" alt="">
                    </div>

                    <div class="text">肺活量</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/羽毛球@2x.png" alt="">
                    </div>

                    <div class="text">羽毛球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/乒乓球@2x.png" alt="">
                    </div>

                    <div class="text">乒乓球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/足球@2x.png" alt="">
                    </div>

                    <div class="text">足球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/篮球@2x.png" alt="">
                    </div>

                    <div class="text">篮球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/排球@2x.png" alt="">
                    </div>

                    <div class="text">排球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/实心球@2x.png" alt="">
                    </div>

                    <div class="text">实心球</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/俯卧撑@2x.png" alt="">
                    </div>

                    <div class="text">俯卧撑</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/视力@2x.png" alt="">
                    </div>

                    <div class="text">视力</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/仰卧起坐备份 2@2x.png" alt="">
                    </div>

                    <div class="text">十字跳</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <img src="./images/1000米@2x.png" alt="">
                    </div>

                    <div class="text">1000米</div>
                </div>
            </div>
        </div>
        <div class="introduceBox">
            <div class="titleBox">
                <div class="title">产品介绍</div>
            </div>
            <div class="introduce">
                <div class="lt">
                    <img src="./images/位图备份@2x.png" alt="">
                </div>
                <div class="headline">
                    <div class="sx"></div>
                    <div class="text">AI体育锻炼屏</div>
                </div>
                <div class="explain">AI体育锻炼屏是一款软件硬件兼备的智能设备，分体壁挂式、落地一体机两种形态灵活布置。内部载有运动视频采集摄像头、AI分析模组，智慧体育锻炼分析引擎等组件。</div>
                <div class="exercise">
                    <div class="left">
                        <img src="./images/截屏2023-09-08 14.57.58@2x.png" alt="">
                    </div>
                    <div class="right">
                        <div class="top">
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 快速捕捉运动姿态，实时打分，规范动作。</div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 快速捕捉运动姿态，实时打分，规范动作。对接体育知识题库，提供各项体测项目提分技巧。将运动与学科相融合，寓教于乐。
                                </div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 定制集“健康知识、基本运动技能、专项运动技能”为一体的多样化教学模式。</div>
                            </div>
                        </div>
                        <div class="btn">
                            <div class="name">精准捕捉</div>
                            <div class="name">实时纠错</div>
                            <div class="name">自助练习</div>
                            <div class="name">寓教于乐</div>
                        </div>
                    </div>
                </div>
                <div class="headline">
                    <div class="sx"></div>
                    <div class="text">AI体育教学测试屏</div>
                </div>
                <div class="explain">AI体育教学测试屏可以提供自动化语音播报讲解功能，适配各种体育课堂教学场景。</div>
                <div class="exercise">
                    <div class="right">
                        <div class="top">
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- AI赋能智慧教学，支持教学示范视频讲解与多种课堂组合的形式。</div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 帮助老师快速掌握学生专项能力测评报告，实时输出体育测试成绩。
                                </div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 全过程跟踪分析，给予最优锻炼建议。</div>
                            </div>
                        </div>
                        <div class="btn">
                            <div class="name">语音指导</div>
                            <div class="name">视频讲解</div>
                            <div class="name">专业评测</div>
                            <div class="name">AI赋能</div>
                        </div>
                    </div>
                    <div class="left" style="width: 206px;height: 261px;">
                        <img src="./images/截屏2023-09-08 15.25.04@2x.png" alt="">
                    </div>
                </div>
                <div class="headline ">
                    <div class="sx"></div>
                    <div class="text">室外AI体育立式体测屏</div>
                </div>
                <div class="explain ">适配以“跑步和立定跳远”为主的专项测试和体能训练。
                </div>
                <div class="exercise ">
                    <div class="left" style="width: 246px;height: 252px;">
                        <img src="./images/截屏2023-09-08 15.25.04备份@2x.png" alt="">
                    </div>
                    <div class="right">
                        <div class="top">
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 结合AI摄像监控杆，支持AI自动视频采集识别运动过程、违规动作提示、自动输出测试成绩。</div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 支持学生个人体测报告输出、学生体测视频回放查看。
                                </div>
                            </div>
                            <div class="it">
                                <div class="y"></div>
                                <div class="text">- 随心挑选体育运动项目，人脸识别，语音播报，日周运动排名，运动评价等多项互动活动。</div>
                            </div>
                        </div>
                        <div class="btn ydw">
                            <div class="name">专项识别</div>
                            <div class="name">AI采集</div>
                            <div class="name">自主上传</div>
                            <div class="name">趣味互动</div>
                            <div class="lts">
                                <img src="./images/位图备份 2@2x.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="IPMIBox">
            <div class="titleBox">
                <div class="title">智慧体育数据平台</div>
            </div>
            <div class="IPMI">
                <div class="left">
                    <div style="width: 200px;">
                        <p>全盘把握体测结果</p>
                        <p>快速发现学生的薄弱项</p>
                        <p>及时调整体育教学方案</p>
                    </div>
                    <div style="width: 271px;margin-top:78px ;">结合日常表现，学生身体综合素质，各项体育项目掌握情况，提供运动处方和锻炼建议</div>
                </div>
                <div class="center">
                    <img src="./images/855b21b14919cae1e6a987491f4a528d402a3b547cdb9-ImHsal@2x.png" alt="">
                </div>
                <div class="right">
                    <div style="width: 140px;">
                        <p>学校数据平台</p>
                        <p>全校数据共享</p>
                        <p>可视化分析管理</p>
                    </div>
                    <div style="width: 271px;margin-top:78px ;">
                        <p>体育大数据</p>
                        <p>提供多维度个性化精细化解决方案</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sportsBox">
            <div class="titleBox">
                <div class="title">体育云平台</div>
            </div>
            <div class="decipher">移动化应用：随时随地了解运动成效</div>
            <div class="sports">
                <div class="left">
                    <div class="img1">
                        <img src="./images/截屏2023-09-08 16.07.25@2x.png" alt="">
                    </div>
                    <div class="text">
                        <div>学校</div>
                        <div>教师</div>
                        <div>学生</div>
                        <div>家长</div>
                    </div>
                </div>
                <div class="right">
                    <div class="item">
                        <div class="title">灵活</div>
                        <div class="text">微信小程序移动数字端适配多样场景，随时随地趣味锻炼。</div>
                    </div>
                    <div class="item">
                        <div class="title">互动</div>
                        <div class="text">家校互育，教师、学生、家长全方位了解学生体育定制化计划。</div>
                    </div>
                    <div class="item">
                        <div class="title">便捷</div>
                        <div class="text">可视化数据和分析结果云同步。</div>
                    </div>
                    <div class="item">
                        <div class="title">趣味</div>
                        <div class="text">自主锻炼和趣味挑战激发学生体育兴趣。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="SwiperBox">
            <div class="titleBox">
                <div class="title">用户案例/合作学校</div>
            </div>
            <div class="Swiper">
                <SwiperTwo />
            </div>
        </div>
    </div>
</template>
  
<script>
import SwiperTwo from "@/components/swiperTwo";
export default {
    name: 'EveryTreeAndBushIndex',
    components: {
        SwiperTwo
    },
    data() {
        return {
        }
    },

    mounted() {

    },

    methods: {

    },
};
</script>
  
<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sceneBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .title {
        font-size: 56px;
        margin-bottom: 80px;
    }

    .scene {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;



        .item {
            width: 48%;
            height: 352px;
            background: #F6F7F9;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;

            .img {
                width: 278px;
                height: 217px;
            }

            .text {
                margin-top: 30px;
                font-size: 32px;
            }
        }
    }
}

.projectBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .title {
        font-size: 56px;
        margin-bottom: 80px;
    }

    .project {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: 18%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 45px;

            .icon {
                width: 45px;
                height: 45px;
            }

            .text {
                height: 45px;
                font-size: 32px;
            }
        }
    }
}

.introduceBox {
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .titleBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 56px;
        margin-bottom: 80px;
    }

    .introduce {
        width: 100%;
        position: relative;

        .lt {
            position: absolute;
            top: 0;
            left: -360px;
            width: 419px;
            height: 474px;
        }

        .headline {
            display: flex;
            align-items: center;
            font-size: 36px;

            .sx {
                width: 6px;
                height: 40px;
                background: #3964E6;
            }

            .text {
                margin-left: 20px;
            }
        }

        .explain {
            margin: 21px 0 85px 0;
            font-size: 24px;
            margin-left: 26px;
        }



        .exercise {
            display: flex;
            justify-content: space-around;
            padding-bottom: 101px;

            .left {
                width: 245px;
                height: 227px;
            }

            .right {
                font-size: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .top {
                    .it {
                        display: flex;
                        align-items: center;
                        margin-bottom: 37px;

                        .y {
                            width: 20px;
                            height: 20px;
                            background: #3964E6;
                            border-radius: 50%;
                            margin-right: 11px;
                        }

                        .text {
                            width: 746px;
                        }
                    }

                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 28px;
                    font-weight: bold;

                }

                .ydw {
                    position: relative;

                    .lts {
                        position: absolute;
                        bottom: 0;
                        right: -360px;
                        width: 472px;
                        height: 386px;
                    }
                }
            }
        }
    }
}

.IPMIBox {
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .titleBox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 56px;
            margin-bottom: 80px;
        }
    }

    .IPMI {
        display: flex;
        justify-content: space-around;
        font-size: 20px;

        .center {
            width: 500px;
            height: 291px;
        }
    }
}

.sportsBox {
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .titleBox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 56px;
            margin-bottom: 80px;
        }
    }

    .decipher {
        font-size: 36px;
        margin-bottom: 70px;
    }

    .sports {
        display: flex;
        justify-content: space-around;
        font-size: 20px;

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .img1 {
                width: 392px;
                height: 294px;
            }

            .text {
                margin-top: 30px;
                width: 392px;
                display: flex;
                font-size: 28px;
                align-items: center;
                justify-content: space-around;
            }
        }

        .right {
            .item {
                .title {
                    margin-bottom: 15px;
                    color: #3964E6;
                    font-size: 28px;
                }

                .text {
                    font-size: 20px;
                    margin-bottom: 37px;
                }
            }
        }
    }
}

.SwiperBox {
    padding: 0 10%;
    background: #fff;
    padding-top: 140px;

    .titleBox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 56px;
            margin-bottom: 80px;
        }
    }

    .Swiper {
        width: 100%;
        height: 590px;
    }
}
</style>