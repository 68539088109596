<template>
  <div>
    <!-- 底部联系我们 -->
    <div class="ContactUsBox">
      <div class="title">联系我们</div>
      <div>Contact Us</div>
      <div class="tit">
        <div class="left">关于我们</div>
        <div class="right">联系我们</div>
      </div>
      <div class="conten">
        <div class="left">
          <div class="item">生涯规划</div>
          <div class="item">留学规划</div>
          <div class="item">智慧教学</div>
          <div class="item">考研规划</div>
          <div class="item">智慧校园</div>
          <div class="item">智慧体育</div>
        </div>
        <div class="right">
          <div class="top">
            <div class="phoneBox">
              <div class="ico" style="width: 21px; height: 21px">
                <img src="./images/电话@2x.png" alt="" />
              </div>
              <div>电话：400-621-1003</div>
            </div>
            <div class="mailBox">
              <div class="ico" style="width: 20px; height: 15px">
                <img src="./images/邮箱@2x.png" alt="" />
              </div>
              <div>邮箱：126754763@163.com</div>
            </div>
            <div class="siteBox">
              <div class="ico" style="width: 18px; height: 20px">
                <img src="./images/导航地址@2x.png" alt="" />
              </div>
              <div>地址：上海杨浦区政学路88号5号楼1003室</div>
            </div>
          </div>
          <div class="btn">
            <div class="qrcode">
              <div class="qcIc">
                <img src="./images/1.jpg" alt="">
              </div>
              <div class="name">关注微信官方账号</div>
            </div>
            <div class="qrcode">
              <div class="qcIc">
                <img src="./images/2.jpg" alt="">
              </div>
              <div class="name">六纬考研</div>
            </div>
            <div class="qrcode">
              <div class="qcIc">
                <img src="./images/3.jpg" alt="">
              </div>
              <div class="name">六纬体测</div>
            </div>
            <div class="qrcode">
              <div class="qcIc">
                <img src="./images/4.jpg" alt="">
              </div>
              <div class="name">六纬生涯</div>
            </div>
            <div class="qrcode">
              <div class="qcIc">
                <img src="./images/5.jpg" alt="">
              </div>
              <div class="name">六纬志愿</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EveryTreeAndBushIndex',

  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
.ContactUsBox {
  width: 100%;
  height: 648px;
  background: #0b0756;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;

  .title {
    margin: 69px 0 6px 0;
    font-size: 56px;
  }

  .tit {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding-left: 326px;
    font-size: 24px;
    margin-bottom: 35px;

    .left {
      width: 203px;
      margin-right: 33px;
    }
  }

  .conten {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding-left: 326px;
    font-size: 20px;

    .left {
      width: 203px;
      margin-right: 33px;
    }

    .item {
      margin-bottom: 11px;
    }

    .right {
      .top {
        display: flex;

        .phoneBox,
        .mailBox,
        .siteBox {
          display: flex;
          margin-right: 53px;

          .ico {
            margin-right: 6px;
          }
        }
      }

      .btn {
        margin-top: 40px;
        display: flex;

        .qrcode {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 36px;

          .qcIc {
            width: 70px;
            height: 70px;
            border-radius: 5px;
            margin-bottom: 17px;
          }
        }
      }
    }
  }
}
</style>