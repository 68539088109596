<template>
  <!-- 顶部轮播图 -->
  <div class="slideshowBox">
    <div class="navBox" @scroll.passive="all(e)">
      <div class="logo">
        <img src="./images/logo@2x.png" alt="" />
      </div>
      <div class="navBar">
        <div :class="active == it.path ? 'nav navActive' : 'nav'" v-for="(it, idex) in list" :key="it.title"
          @click="goPath(it.path, idex)">
          {{ it.title }}
        </div>
      </div>
    </div>
    <Swiper style="margin-top: 100px;" :shower="shower" />
  </div>
</template>

<script>
import Swiper from '@/components/swiper'
export default {
  name: 'OCRrecognitionIndex',
  components: {
    Swiper
  },
  data() {
    return {
      shower: 0,
      active: '/Home/Homepage',
      list: [
        {
          title: '首页',
          path: '/Home/Homepage'
        },
        {
          title: '生涯规划',
          path: '/Home/CareerPlanning'
        }, {
          title: '留学规划',
          path: '/Home/StudyingAbroad'
        },
        {
          title: '智慧教学',
          path: '/Home/WisdomTeaching'
        },
        {
          title: '考研规划',
          path: '/Home/MbaProject'
        },
        {
          title: '智慧校园',
          path: '/Home/SmartCampus'
        },
        {
          title: '智慧体育',
          path: '/Home/SmartSports'
        },
        {
          title: '关于我们',
          path: ''
        },
      ]
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler(val) {
        console.log(val);//新路由信息
        this.active = val.path
      },
      // 深度观察监听
      immediate: true
    }
  },
  mounted() {

  },

  methods: {
    all(e) {
      console.log('e', e.target);
    },
    goPath(path, idex) {
      if (this.active !== path) {
        if (idex !== 7) {
          this.shower = idex
          this.$router.push(path)
        } else {
          this.shower = 0
          this.$router.push('/Home/Homepage')
          setTimeout(() => {
            window.scrollTo({
              top: 4900,
              behavior: "smooth"
            });
          }, 200);
        }
      }
    }
  },
};
</script>

<style lang="less">
img {
  width: 100%;
  height: 100%;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
}

.slideshowBox {
  position: relative;

  .navBox {
    z-index: 22;
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    height: 100px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  .logo {
    z-index: 22;
    min-width: 126px;
    height: 50px;
    margin-left: 50px;
  }

  .navBar {
    cursor: pointer;
    z-index: 22;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    min-width: 1200px;
    max-width: 1200px;
    margin-right: 15%;

    .nav {
      margin-left: 3%;
    }

    .nav:nth-child(1) {
      margin-left: 82px !important;
    }

    .nav:hover {
      color: #3964e6;
    }

    .navActive {
      color: #3964e6;
      font-weight: bold;
    }
  }
}
</style>
