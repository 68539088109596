<template>
  <div>
    <router-view />
    <!-- 返回顶部 -->
    <back-to-top />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="less">
body {
  background: #f3f3f3;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
