<template>
  <div class="box">
    <!--  第一部分 -->
    <div class="AboutUs">
      <!-- 生涯规划 -->
      <div class="careerPlanning">
        <div class="title">生涯规划</div>
        <div>Career Planning</div>
        <div class="title1">个性测评多元评估 升学路径一览无余</div>
        <div class="explain">
          长期陪伴式数据跟踪，制定个性化服务，准确适配海内外20+种升学途径。筛掉风险，助力学生未来成长
        </div>
      </div>
      <div class="appraisalList">
        <div class="sxgh">
          <img src="./images/编组 3@2x.png" alt="">
        </div>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="SmartTeachingBox">
      <div class="title">智慧教学</div>
      <div>Smart teaching</div>
      <div class="title1">教学课堂实时联动 资源整合精准教学</div>
      <div class="explain">
        AI大数据智能引擎实现全场景数据采集，集课堂真实表现，学习成果实时追踪为一体，从整体把控宏观学情，科学指导教学计划，快速对焦错题难题
      </div>
      <div class="morBox">
        <div class="mor">点击查看更多</div>
        <i style="font-weight: 700; margin-left: 15px" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- 第三部分 -->
    <div class="GraduateEntranceBox">
      <div class="title">考研规划</div>
      <div>Graduate Entrance Examination Plan</div>
      <div class="btn">
        <div class="left">
          <img src="./images/位图@2x(4).png" alt="" />
        </div>
        <div class="right">
          <div class="title3">考研规划覆盖需求 封闭训练悉心服务</div>
          <div class="title4">
            考研规划紧密适配学生能力。封闭化训练悉心服务，多层次课程设定全方位覆盖学生需求，考后报考指导帮助学生逐梦高校
          </div>
          <div class="morBox">
            <div class="mor">点击查看更多</div>
            <i style="font-weight: 700; margin-left: 15px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="SmartCampusBox">
      <div class="title">智慧校园</div>
      <div>Smart Campus</div>
      <div class="title1">校园安全一屏全览，师生管理一网统办</div>
      <div class="explain">
        全流程追踪多样设备，可视化数据简单操作。管理模式智能分析，高效解决课堂问题
      </div>
      <div class="icon">
        <img src="./images/位图@2x(5).png" alt="" />
      </div>
    </div>
    <!-- 第五部分 -->
    <div class="wisdomBox">
      <div class="wisdomBar">
        <div class="title">智慧管理</div>
        <div class="rightIcon">
          <img src="./images/按钮-箭头向右_o@2x.png" alt="" />
        </div>
      </div>
      <div class="wisdomBar">
        <div class="title">智慧安全</div>
        <div class="rightIcon">
          <img src="./images/按钮-箭头向右_o@2x.png" alt="" />
        </div>
      </div>
      <div class="wisdomBar">
        <div class="title">智慧环境</div>
        <div class="rightIcon">
          <img src="./images/按钮-箭头向右_o@2x.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 第六部分 -->
    <div class="SmartSportsBox">
      <div class="title">智慧体育</div>
      <div>Smart Sports</div>
      <div class="title1">数字终端智能辅助 家校互育科学锻炼</div>
      <div class="explain">
        学校、家长、学生三方数据联动，智能化体测结果分析。AI辅助自助锻炼，定期活动组织，全方位激发学生体育热情。
      </div>
      <div class="morBox">
        <div class="mor">点击查看更多</div>
        <i style="font-weight: 700; margin-left: 15px" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="AboutUs">
      <div class="title">关于我们</div>
      <div>About us</div>
      <div class="title1">教育+科技整体方案提供商</div>
      <div class="explain">
        上海一草一木教育科技有限公司位于上海教育科技高地，借助上海科创产业扶持政策，以高校科技成果转化为核心开展业务。公司与上海本地多所国内知名高校合作，充分发挥产学研融合优势，通过建立研究生实习基地、知名教授合作工作室等方式，为高校科技成果提供产业实践环境，同时解决教育科技产业需求，真正实现科研成果落地。
      </div>
      <!-- 关于我们 -->
      <div class="introductoryBox">
        <div class="left">
          <div class="introductory">
            <div class="title2">服务简介</div>
            <div>聚焦国内教育需求，适配当代青少年学习成长规律</div>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <div class="introductory1">
              <div class="title2">服务对象</div>
              <div>
                学生、家庭、学校、教育主管部门、社会教育机构等多方教育共同体
              </div>
            </div>
          </div>
          <div class="btn">
            <div class="Services">
              <div class="title2">服务领域</div>
              <div>对标国内外教育需求，提供全面精准、定制化的解决方案。</div>
            </div>
            <div class="Services1">
              <div class="title2">服务理念</div>
              <div>遵循“学职衔接”、“尊重个性、激发潜能”教育理念</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EveryTreeAndBushIndex',
  components: {
  },
  data() {
    return {
    }
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
}

.slideshowBox {
  position: relative;

  .logo {
    z-index: 22;
    position: absolute;
    top: 21px;
    left: 205px;
    width: 126px;
    height: 50px;
  }

  .navBar {
    cursor: pointer;
    z-index: 22;
    position: absolute;
    top: 21px;
    right: 216px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    .nav {
      margin-left: 72px;
    }

    .nav:hover {
      color: #3964e6;
    }

    .navActive {
      color: #3964e6;
      font-weight: bold;
    }
  }
}

.AboutUs {
  background: #ffffff;
  height: 100%;
  padding: 0 10% 30px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;

  .title {
    margin: 140px 0 6px 0;
    font-size: 56px;
  }

  .title1 {
    margin: 80px 0 28px 0;
    font-size: 50px;
  }

  .explain {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
  }

  .introductoryBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 58px;

    .left {
      margin-right: 30px;
      margin-left: 10%;

      .introductory {
        min-width: 435px;
        height: 360px;
        background-image: url("./images/编组 8@2x.png");
        background-size: 100%;
        box-sizing: border-box;
        padding: 0 23px 0 40px;

        .title2 {
          font-size: 42px;
          color: #ffffff;
          font-weight: bold;
          padding: 94px 0 29px 0;
        }

        div {
          font-size: 20px;
          color: #ffffff;
        }
      }
    }

    .right {
      height: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 10%;

      .top {
        min-width: 735px;
        height: 165px;
        background-image: url("./images/编组 9@2x.png");
        background-size: 100%;

        .introductory1 {
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .title2 {
          font-size: 42px;
          color: #ffffff;
          font-weight: bold;
        }

        div {
          margin-top: 15px;
          font-size: 20px;
          color: #ffffff;
        }
      }

      .btn {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .Services {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 353px;
          height: 166px;
          padding: 0 26px;
          background-image: url("./images/编组 10@2x.png");
          background-size: 100%;
          margin-right: 20px;

          .title2 {
            font-size: 42px;
            color: #ffffff;
            font-weight: bold;
          }

          div {
            height: 53px;
            margin-top: 17px;
            font-size: 20px;
            color: #ffffff;
          }
        }

        .Services1 {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 353px;
          height: 166px;
          padding: 0 26px;
          background-image: url("./images/编组 11@2x.png");
          background-size: 100%;

          .title2 {
            font-size: 42px;
            color: #ffffff;
            font-weight: bold;
          }

          div {
            height: 53px;
            margin-top: 17px;
            font-size: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .careerPlanning {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
  }

  .appraisalList {
    width: 100%;
    margin-top: 83px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .sxgh {
      width: 440px;
      height: 520px;
    }


  }
}

.SmartTeachingBox {
  width: 100%;
  height: 564px;
  background-image: url("./images/编组 5@2x.png");
  background-size: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 69px 0 6px 0;
    font-size: 56px;
  }

  .title1 {
    margin: 80px 0 28px 0;
    font-size: 50px;
  }

  .explain {
    padding: 0 10%;
    font-size: 24px;
    text-align: center;
  }

  .morBox {
    margin-top: 37px;
    display: flex;
    align-items: baseline;
    font-size: 24px;
    color: #f69351;
    cursor: pointer;
  }
}

.GraduateEntranceBox {
  box-sizing: border-box;
  width: 100%;
  height: 660px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10% 0 10%;

  .title {
    margin: 69px 0 6px 0;
    font-size: 56px;
  }

  .btn {
    width: 100%;
    display: flex;
    align-items: center;

    .left {
      width: 446px;
      min-width: 223px;
      box-sizing: border-box;
      margin-right: 128px;
    }

    .right {
      flex: 1;

      .title3 {
        width: 417px;
        height: 140px;
        font-size: 50px;
        font-weight: bold;
      }

      .title4 {
        font-size: 24px;
      }

      .morBox {
        margin-top: 37px;
        display: flex;
        align-items: baseline;
        font-size: 24px;
        cursor: pointer;
        color: #f69351;
      }
    }
  }
}

.SmartCampusBox {
  width: 100%;
  height: 869px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 69px 0 6px 0;
    font-size: 56px;
  }

  .title1 {
    margin: 80px 0 28px 0;
    font-size: 50px;
  }

  .explain {
    font-size: 24px;
    text-align: center;
  }

  .icon {
    margin-top: 61px;
    width: 528px;
    height: 381px;
  }
}

.wisdomBox {
  width: 100%;
  height: 207px;
  background-image: url("./images/编组 13@2x.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .wisdomBar {
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 50px;

    .rightIcon {
      width: 36px;
      height: 36px;
      margin-left: 15px;
    }

    .title {
      color: #ffffff;
    }
  }
}

.SmartSportsBox {
  width: 100%;
  padding: 0 10%;
  height: 638px;
  background-image: url("./images/编组 14@2x.png");
  background-size: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 69px 0 6px 0;
    font-size: 56px;
  }

  .title1 {
    margin: 80px 0 28px 0;
    font-size: 50px;
  }

  .explain {
    font-size: 24px;
    text-align: center;
  }

  .morBox {
    margin-top: 37px;
    display: flex;
    align-items: baseline;
    font-size: 24px;
    cursor: pointer;
    color: #f69351;
  }
}
</style>